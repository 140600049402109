import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
const required = "This field is required";
const maxLength = "Your input exceed maximum length";
const pattern = "Please enter a valid ";

const errorMessage = (error) => {
  return <span className='text-red-500 text-xs italic'>{error}</span>;
};
const validMessage = (msg) => {
  return <span className='text-green-500 text-xs italic'>{msg}</span>;
};

const InquiryForm = ({ setInquiryTab, mobileNo, sources }) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
  } = useForm({
    mode: "all",
  });

  const watchSource = watch("Source");
  const onSubmit = async (data) => {
    await axios({
      headers: {},
      url: "https://apiiiht.iihtsrt.com/public/inquiry/save",
      method: "post",
      data: data,
    }).then((res) => {
      console.log(res);
    });
    console.log(data);
    setInquiryTab("InquiryThankyou");
  };

  useEffect(() => {
    sources && sources.length > 0 && setValue("Source", sources[0].source_name);
  }, [sources]);
  return (
    <>
      <h2 className='text-center text-3xl font-semibold my-3'>Inquiry Form</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type='hidden' value={"ADJ"} {...register("Branch")} />
        {/* <input type='hidden' value={"RRS"} {...register("Branch")} /> */}
        <label htmlFor='' className='inline-block mb-1 font-medium '>
          Full Name
        </label>
        <div className='grid lg:grid-cols-3 lg:gap-4'>
          <div>
            <input
              type='text'
              placeholder='First Name'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("FirstName", {
                required: true,
                pattern: /^[a-zA-Z ]{1,20}$/i,
              })}
            />
            <p>
              {errors.FirstName &&
                errors.FirstName.type === "required" &&
                errorMessage(required)}
              {errors.FirstName &&
                errors.FirstName.type === "pattern" &&
                errorMessage(pattern + "First Name")}
              {!errors.FirstName &&
                touchedFields.FirstName &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
          <div>
            <input
              type='text'
              placeholder='Middle Name'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("MiddleName", {
                required: true,
                pattern: /^[a-zA-Z ]{1,20}$/i,
              })}
            />
            <p>
              {errors.MiddleName &&
                errors.MiddleName.type === "required" &&
                errorMessage(required)}
              {errors.MiddleName &&
                errors.MiddleName.type === "pattern" &&
                errorMessage(pattern + "Middle Name")}
              {!errors.MiddleName &&
                touchedFields.MiddleName &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
          <div>
            <input
              type='text'
              placeholder='Last Name'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("LastName", {
                required: true,
                pattern: /^[a-zA-Z ]{1,20}$/i,
              })}
            />
            <p>
              {errors.LastName &&
                errors.LastName.type === "required" &&
                errorMessage(required)}
              {errors.LastName &&
                errors.LastName.type === "pattern" &&
                errorMessage(pattern + "Last Name")}
              {!errors.LastName &&
                touchedFields.LastName &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
        </div>
        <div className='grid lg:grid-cols-2 lg:gap-4'>
          <div>
            <label htmlFor='' className='inline-block mb-1 font-medium '>
              Date Of Birth
            </label>
            <input
              type='date'
              placeholder='Date Of Birth'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("DateOfBirth", { required: true })}
            />
            <p>
              {errors.DateOfBirth &&
                errors.DateOfBirth.type === "required" &&
                errorMessage(required)}
              {!errors.DateOfBirth &&
                touchedFields.DateOfBirth &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
          <div>
            <label htmlFor='' className='inline-block mb-1 font-medium '>
              Gender
            </label>
            <div className='flex-grow flex gap-4 w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'>
              <div className='flex items-center'>
                <input
                  id='male'
                  {...register("Gender", { required: true })}
                  type='radio'
                  className='h-4 w-4 border-gray-300 text-deep-purple-400 focus:border-deep-purple-400'
                  value={"male"}
                  defaultChecked={true}
                />
                <label
                  htmlFor='male'
                  className='ml-3 block text-sm font-medium text-gray-700'
                >
                  Male
                </label>
              </div>
              <div className='flex items-center'>
                <input
                  id='female'
                  {...register("Gender", { required: true })}
                  type='radio'
                  className='form-radio h-4 w-4 border-gray-300 text-deep-purple-400 focus:ring-deep-purple-400'
                  value={"female"}
                />
                <label
                  htmlFor='female'
                  className='ml-3 block text-sm font-medium text-gray-700'
                >
                  Female
                </label>
              </div>
            </div>
          </div>
          <div>
            <label htmlFor='' className='inline-block mb-1 font-medium '>
              Email
            </label>
            <input
              type='email'
              placeholder='Email'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("Email", {
                required: true,
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/i,
              })}
            />
            <p>
              {errors.Email &&
                errors.Email.type === "required" &&
                errorMessage(required)}
              {errors.Email &&
                errors.Email.type === "pattern" &&
                errorMessage(pattern + "Email")}
              {!errors.Email &&
                touchedFields.Email &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
          <div>
            <label htmlFor='' className='inline-block mb-1 font-medium '>
              Course
            </label>
            <input
              type='text'
              placeholder='Course'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("Course", {
                required: true,
                pattern: /^[a-zA-Z \-+./0-9]{1,50}$/i,
              })}
            />
            <p>
              {errors.Course &&
                errors.Course.type === "required" &&
                errorMessage(required)}
              {errors.Course &&
                errors.Course.type === "pattern" &&
                errorMessage(pattern + "Course")}
              {!errors.Course &&
                touchedFields.Course &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
        </div>
        <label htmlFor='' className='inline-block mb-1 font-medium '>
          Address
        </label>
        <div>
          <input
            type='text'
            placeholder='Area'
            className='flex-grow w-full h-12 px-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
            {...register("Area", { required: true })}
          />
          <p>
            {errors.Area &&
              errors.Area.type === "required" &&
              errorMessage(required)}
            {errors.Area &&
              errors.Area.type === "pattern" &&
              errorMessage(pattern + "Area")}
            {!errors.Area && touchedFields.Area && validMessage("Thank You")}
            &nbsp;
          </p>
        </div>
        <div className='grid lg:grid-cols-3 lg:gap-4'>
          <div>
            <input
              type='text'
              placeholder='Land Mark'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("LandMark", { required: true })}
            />
            <p>
              {errors.LandMark &&
                errors.LandMark.type === "required" &&
                errorMessage(required)}
              {errors.LandMark &&
                errors.LandMark.type === "pattern" &&
                errorMessage(pattern + "LandMark")}
              {!errors.LandMark &&
                touchedFields.LandMark &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
          <div>
            <input
              type='text'
              placeholder='City'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("City", { required: true })}
            />
            <p>
              {errors.City &&
                errors.City.type === "required" &&
                errorMessage(required)}
              {errors.City &&
                errors.City.type === "pattern" &&
                errorMessage(pattern + "City")}
              {!errors.City && touchedFields.City && validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
          <div>
            <input
              type='text'
              placeholder='State'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("State", { required: true })}
            />
            <p>
              {errors.State &&
                errors.State.type === "required" &&
                errorMessage(required)}
              {errors.State &&
                errors.State.type === "pattern" &&
                errorMessage(pattern + "State")}
              {!errors.State &&
                touchedFields.State &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
        </div>
        <label htmlFor='' className='inline-block mb-1 font-medium '>
          Contact
        </label>
        <div className='grid lg:grid-cols-2 lg:gap-4'>
          <div>
            <div className='relative'>
              <div className='pointer-events-none absolute top-0 bottom-0.5 left-0 flex items-center px-3'>
                <span className='text-gray-500 sm:text-sm'>+91 </span>
              </div>
              <input
                type='tel'
                placeholder='Mobile'
                className='flex-grow w-full h-12 pl-10 pr-4 transition duration-200 bg-slate-100 border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
                {...register("Mobile", {
                  required: true,
                  pattern: /^[6-9]\d{9}$/i,
                })}
                defaultValue={mobileNo}
                readOnly
              />
            </div>
            <p>
              {errors.Mobile &&
                errors.Mobile.type === "required" &&
                errorMessage(required)}
              {errors.Mobile &&
                errors.Mobile.type === "pattern" &&
                errorMessage(pattern + "Mobile")}
              {!errors.Mobile &&
                touchedFields.Mobile &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
          <div>
            <div className='relative'>
              <div className='pointer-events-none absolute top-0 bottom-0.5 left-0 flex items-center px-3'>
                <span className='text-gray-500 sm:text-sm'>+91 </span>
              </div>
              <input
                type='tel'
                placeholder='Father/ Guardian Mobile'
                className='flex-grow w-full h-12 pl-10 pr-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
                {...register("FatherGuardianMobile", {
                  required: true,
                  pattern: /^[6-9]\d{9}$/i,
                })}
              />
            </div>
            <p>
              {errors.FatherGuardianMobile &&
                errors.FatherGuardianMobile.type === "required" &&
                errorMessage(required)}
              {errors.FatherGuardianMobile &&
                errors.FatherGuardianMobile.type === "pattern" &&
                errorMessage(pattern + "Father Guardian Mobile")}
              {!errors.FatherGuardianMobile &&
                touchedFields.FatherGuardianMobile &&
                validMessage("Thank You")}
              &nbsp;
            </p>
          </div>
        </div>
        <div>
          <label htmlFor='' className='inline-block mb-1 font-medium '>
            How did you get to know about IIHT?
          </label>
          <select
            className="  className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'"
            {...register("Source", { required: true })}
          >
            {sources &&
              sources.length > 0 &&
              sources.map((source, sourceIndex) => {
                return (
                  <option key={sourceIndex} value={source.source_name}>
                    {source.source_name}
                  </option>
                );
              })}
          </select>
          <p>
            {errors.Source &&
              errors.Source.type === "required" &&
              errorMessage(required)}
            {errors.Source &&
              errors.Source.type === "pattern" &&
              errorMessage(pattern + "Source")}
            {!errors.Source &&
              touchedFields.Source &&
              validMessage("Thank You")}
            &nbsp;
          </p>
          {watchSource == "REFERAL" && (
            <div>
              <input
                type='text'
                placeholder='Referal Name'
                className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
                {...register("ReferalName", { required: true })}
              />
              <p>
                {errors.ReferalName &&
                  errors.ReferalName.type === "required" &&
                  errorMessage(required)}
                {errors.ReferalName &&
                  errors.ReferalName.type === "pattern" &&
                  errorMessage(pattern + "Referal Name")}
                {!errors.ReferalName &&
                  touchedFields.ReferalName &&
                  validMessage("Thank You")}
                &nbsp;
              </p>
            </div>
          )}
        </div>

        <div className='mt-4 mb-2 sm:mb-4'>
          <button
            type='submit'
            className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none'
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default InquiryForm;
