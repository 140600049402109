import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
const required = "This field is required";
const maxLength = "Your input exceed maximum length";
const pattern = "Please enter a valid ";

const errorMessage = (error) => {
  return <span className='text-red-500 text-xs italic'>{error}</span>;
};
const validMessage = (msg) => {
  return <span className='text-green-500 text-xs italic'>{msg}</span>;
};

const InquiryPassword = ({ setInquiryTab, setUserToken }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({
    mode: "all",
  });
  const onSubmit = async (data) => {
    await axios({
      headers: {},
      url: "https://apiiiht.iihtsrt.com/public/user/auth",
      method: "post",
      data: { username: "adjmanager", password: data.Password },
      // data: { username: "rrsmanager", password: data.Password },
    }).then((res) => {
      if (res.data.error === false && res.data.token) {
        setUserToken({
          username: "adjmanager",
          // username: "rrsmanager",
          token: res.data.token,
        });
        setInquiryTab("InquiryList");
      }
    });
  };
  return (
    <>
      <h2 className='text-center text-3xl font-semibold my-3'>
        Please Enter Password
      </h2>
      <form
        onSubmit={() => {
          setInquiryTab("InquiryMain");
        }}
      >
        <div className='mt-4 mb-2 sm:mb-4'>
          <button
            name='newInquiry'
            type='submit'
            className='inline-flex items-center justify-center  h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none'
          >
            Go Back
          </button>
        </div>
      </form>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor='' className='inline-block mb-1 font-medium '>
          Password
        </label>
        <div className=''>
          <div>
            <input
              type='text'
              placeholder='Password'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline'
              {...register("Password", {
                required: true,
                pattern: /^[0-9a-zA-z]{3,12}$/i,
              })}
            />
            <p>
              {errors.Password &&
                errors.Password.type === "required" &&
                errorMessage(required)}
              {errors.Password &&
                errors.Password.type === "pattern" &&
                errorMessage(pattern + "Password")}
              &nbsp;
            </p>
          </div>
        </div>
        <div className='mt-4 mb-2 sm:mb-4'>
          <button
            type='submit'
            className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none'
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default InquiryPassword;
